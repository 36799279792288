<template>
  <v-form ref="grafico">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Nome"
            name="nome"
            placeholder="Nome"
            v-model="produto.nomeProduto"
            :readonly="true"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Descrição"
            name="Descricao"
            placeholder="Descrição"
            v-model="produto.descricao"
            :readonly="true"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Linha"
            name="Linha"
            placeholder="Linha"
            v-model="produto.nomeLinha"
            :readonly="true"
            required
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Marca"
            name="Marca"
            placeholder="Marca"
            v-model="produto.nomeMarca"
            :readonly="true"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Referência"
            name="Referencia"
            placeholder="Referência"
            v-model="produto.referencia"
            :readonly="true"
            required
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Preço de venda"
            name="PrecoVenda"
            placeholder="Preço de venda"
            v-model="produto.precoVenda"
            :readonly="true"
            required
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Sem Comissão"
            name="SemComissao"
            placeholder="Sem Comissão"
            v-model="produto.semComissaoString"
            :readonly="true"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Qtd. nas praças"
            name="QtdPraca"
            v-model="produto.quantidadeNasPracas"
            :readonly="true"
            required
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Qtd. disponível"
            name="QtdDisponivel"
            v-model="produto.quantidadeDisponível"
            :readonly="true"
            required
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
        <v-menu
          class="mr-2"
          ref="retornoDateInicio"
          lazy
          :close-on-content-click="false"
          v-model="dataRetornoInicio"
          transition="scale-transition"
          offset-y
          full-width
          :nudge-bottom="-22"
          max-width="290px"
          :return-value.sync="dataRetornoEntradaInicio"
        >
          <v-text-field
            slot="activator"
            label="Data início retorno"
            v-model="dataRetornoEntradaInicio"
            append-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker v-model="dataRetornoEntradaInicio" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="dataRetornoInicio = false">Cancel</v-btn>
            <v-btn flat color="primary" @click="$refs.retornoDateInicio.save(dataRetornoEntradaInicio)">OK</v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu
          class="mr-2"
          ref="retornoDateFim"
          lazy
          :close-on-content-click="false"
          v-model="dataRetornoFinal"
          transition="scale-transition"
          offset-y
          full-width
          :nudge-bottom="-22"
          max-width="290px"
          :return-value.sync="dataRetornoEntradaFinal"
        >
          <v-text-field
            slot="activator"
            label="Data fim retorno"
            v-model="dataRetornoEntradaFinal"
            append-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker v-model="dataRetornoEntradaFinal" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="dataRetornoFinal = false">Cancel</v-btn>
            <v-btn flat color="primary" @click="$refs.retornoDateFim.save(dataRetornoEntradaFinal)">OK</v-btn>
          </v-date-picker>
        </v-menu>
        <div class="form-btn">
          <v-btn outline @click="atualizarBusca" color="primary">Atualizar gráfico</v-btn>
        </div>
        </div>
        <v-widget title="Vendas/praça - Busca padrão: 120 dias. Para outros períodos utilize o filtro acima" content-bg="white">
          <div slot="widget-content">
            <e-chart 
              v-if="isViewReady"
              :path-option="locationData"
              height="400px"
              width="100%"
              autoresize
            >
            </e-chart>
          </div>
        </v-widget>
      </v-flex>
      <v-flex>
        <div class="form-btn">
          <router-link :to="{ name: 'Produto' }" class="nounderline">
            <v-btn outline>Voltar</v-btn>
          </router-link>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import EChart from "@/components/chart/echart"
import VWidget from "@/components/VWidget"
import Material from "vuetify/es5/util/colors"
import { ServicoProduto } from "../../../servicos/servicoProduto.js"
const servicoProduto = new ServicoProduto()
export default {
  components: {
    VWidget,
    EChart
  },
  data: () => ({
    produto: "",
    vendasPracas: [],
    color: Material,
    dadosCarregados: false,
    dataRetornoInicio: false,
    dataRetornoEntradaInicio: null,
    dataRetornoFinal: false,
    dataRetornoEntradaFinal: null,
    option: null
  }),
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
     servicoProduto.buscarViewById(parseInt(this.id), null, null)
        .then(res => {
          this.produto = res.data
          this.vendasPracas = this.produto.vendasPorPraca.map(m => {
            return {
              praca: m.nomePraca,
              "Qtd venda": m.quantidadeVendaPraca,
              "Qtd saída": m.quantidadeSaidaPraca
            }
          }
        )
        this.option = [
                ['dataset.source',  this.vendasPracas],
                ['color', ['#5470c6', '#91cc75']],
                ['legend.show', true],
                ['xAxis.axisLabel.show', true],
                ['yAxis.axisLabel.show', true],
                ['grid.left', '2%'],
                ['grid.bottom', '5%'],
                ['grid.right', '3%'],
                ['series[0].type', 'bar'],
                ['series[0].areaStyle', {}],
                ['series[0].smooth', true],
                ['series[1].type', 'bar'],
                ['series[1].smooth', true],
                ['series[1].areaStyle', {}]
              ]
        this.dadosCarregados = true
      })
    }
  },
  computed: {
    locationData() {
      return this.option
    },
    isViewReady() {
      return this.dadosCarregados
    }
  },
  methods: {
    atualizarBusca() {
      this.dadosCarregados = false
      servicoProduto.buscarViewById(parseInt(this.id), this.dataRetornoEntradaInicio, this.dataRetornoEntradaFinal)
        .then(res => {
          this.produto = res.data
          this.vendasPracas = this.produto.vendasPorPraca.map(m => {
            return {
              praca: m.nomePraca,
              "Qtd venda": m.quantidadeVendaPraca,
              "Qtd saída": m.quantidadeSaidaPraca
            }
          })
          
        this.option = [
                ['dataset.source', this.vendasPracas],
                ['color', ['#5470c6', '#91cc75']],
                ['legend.show', true],
                ['xAxis.axisLabel.show', true],
                ['yAxis.axisLabel.show', true],
                ['grid.left', '2%'],
                ['grid.bottom', '5%'],
                ['grid.right', '3%'],
                ['series[0].type', 'bar'],
                ['series[0].areaStyle', {}],
                ['series[0].smooth', true],
                ['series[1].type', 'bar'],
                ['series[1].smooth', true],
                ['series[1].areaStyle', {}]
              ]
          this.dadosCarregados = true
      })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
